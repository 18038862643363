* {
  margin: 0;
  scroll-behavior: smooth;
}

.Home {
  font-family: "Krona One";
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

#disappearingProjSection {
  display: none;
}

p {
  font-family: Montserrat;
}

@media only screen and (max-width: 1222px) {
  #disappearingProjSection {
    display: flex;
    flex-direction: row;
    width: 95%;
  }

  #containedProjDesc {
    display: none;
  }
}

@font-face {
  font-family: "Krona One";
  src: local("KronaOne"),
    url(./fonts/KronaOne/KronaOne-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./fonts/Montserrat/static/Montserrat-Medium.ttf) format("truetype");
}

.dark {
  background-color: #040142;
  color: #fff;
}
.light {
  background-color: #fff;
  color: #040142;
}
.customLinks {
  color: #040142;
}

.light a {
  color: #040142;
}
.light .customNav {
  background: #fff;
}

.floating-icon {
  position: fixed;
  bottom: 20px;
  width: 50px;
  height: 50px;
  font-size: 50px;
  right: 20px;
  z-index: 100;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
